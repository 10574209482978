import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, mappedAudioSprites } from '../../config';
import { BgmSoundTypes, EventTypes, GameMode, ReelId, reelSets } from '../../global.d';
import {
  setCurrentFreeSpinsTotalWin,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setIsFreeSpinModeSceneChange,
  setIsSpSymbolLotOpening,
} from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { easeOutBaseQuint, easeOutLogoQuint } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import {
  SCENE_CHANGE_BACKGROUND_COLOR,
  SCENE_CHANGE_BASE_SCREEN_ANIMATION_TIME,
  SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME,
  SCENE_CHANGE_LOGO_OUT_SCREEN_ANIMATION_TIME,
  SCENE_CHANGE_LOGO_SCREEN_ANIMATION_TIME,
  eventManager,
} from '../config';

type Dict = {
  [key: string]: GameMode;
};

const spSymbolList: Dict = {};
spSymbolList[ReelId.FREE_SPIN_B] = GameMode.FREE_SPIN_B;
spSymbolList[ReelId.FREE_SPIN_C] = GameMode.FREE_SPIN_C;
spSymbolList[ReelId.FREE_SPIN_D] = GameMode.FREE_SPIN_D;
spSymbolList[ReelId.FREE_SPIN_E] = GameMode.FREE_SPIN_E;
spSymbolList[ReelId.FREE_SPIN_F] = GameMode.FREE_SPIN_F;
spSymbolList[ReelId.FREE_SPIN_G] = GameMode.FREE_SPIN_G;
spSymbolList[ReelId.FREE_SPIN_H] = GameMode.FREE_SPIN_H;
spSymbolList[ReelId.FREE_SPIN_I] = GameMode.FREE_SPIN_I;
spSymbolList[ReelId.FREE_SPIN_J] = GameMode.FREE_SPIN_J;

class SceneChange extends PIXI.Container {
  private rect: PIXI.Graphics;

  private image = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));

  private SceneChangeSize: { width: number; height: number };

  constructor() {
    super();

    this.visible = false;
    this.SceneChangeSize = { width: 1980, height: 1080 };
    this.rect = new PIXI.Graphics();
    this.rect.beginFill(SCENE_CHANGE_BACKGROUND_COLOR);
    this.rect.drawRect(0, 0, this.SceneChangeSize.width, this.SceneChangeSize.height);
    this.rect.position.set(0, 0);
    this.addChild(this.rect);
    this.addChild(this.image);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeScale.bind(this));

    eventManager.addListener(EventTypes.SCENE_CHANGE_START, this.sceneChangeStart.bind(this));
  }

  private sceneChangeStart(isFreeSpins: boolean, _reelPositions?: number[], _reelSetId?: string) {
    setTimeout(() => {
      this.visible = true;
    }, 1);
    setIsSpSymbolLotOpening(true);
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOutGroup = new AnimationGroup();
    const sceneChangeIn = this.getSceneChangeBaseInAnimation(SCENE_CHANGE_BASE_SCREEN_ANIMATION_TIME);
    sceneChangeIn.addOnStart(() => {
      setIsFreeSpinModeSceneChange(true);
      const animation = new AnimationGroup();
      animation.addAnimation(this.getSceneChangeLogoInAnimation(SCENE_CHANGE_LOGO_SCREEN_ANIMATION_TIME));
      animation.start();
      AudioApi.play({ type: ISongs.Wipe });
      BgmControl.stopBgm();
    });

    sceneChangeIn.addOnComplete(() => {
      if (isFreeSpins) {
        eventManager.emit(EventTypes.SP_SYMBOL_LOT_START);
      }
    });

    const sceneChangeOutWait = this.getFadeAnimationWait(SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME);
    const fadeOut = this.getSceneChangeOutBaseAnimation(SCENE_CHANGE_LOGO_SCREEN_ANIMATION_TIME);

    fadeOut.addOnStart(() => {
      const animation = new AnimationGroup();
      animation.addAnimation(this.getSceneChangeOutLogoAnimation(SCENE_CHANGE_LOGO_OUT_SCREEN_ANIMATION_TIME));
      animation.start();
    });

    sceneChangeOutWait.addOnStart(() => {
      const freeSpinsBonus = SlotMachine.getInstance().getFreeSpinBonus();
      const gameMode = spSymbolList[freeSpinsBonus?.bonus.reelSetId!]!;

      let mode = gameMode;
      let reelPositions = [0, 0, 0, 0, 0];
      let reelSetId = reelSets[gameMode]!;
      if (!isFreeSpins) {
        mode = GameMode.REGULAR;
        reelPositions = _reelPositions!;
        reelSetId = _reelSetId!;
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode,
        reelPositions,
        reelSetId,
      });
      if (setFreeRoundsBonus().isActive && setFreeRoundsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      } else if (isFreeSpins) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
    });

    animationChain.addOnComplete(() => {
      setIsFreeSpinModeSceneChange(false);
      this.visible = false;
    });

    animationChain.appendAnimation(sceneChangeIn);
    animationChain.appendAnimation(sceneChangeOutWait);
    fadeOutGroup.addAnimation(fadeOut);
    animationChain.appendAnimation(fadeOutGroup);
    animationChain.start();

    const wipeEndDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.Wipe].duration);
    let bgm: BgmSoundTypes;
    if (isFreeSpins) {
      bgm = BgmSoundTypes.BB_Start_Before;
    } else {
      bgm = BgmSoundTypes.BASE;
    }
    wipeEndDelay.addOnComplete(() => {
      BgmControl.playBgm(bgm);
    });

    wipeEndDelay.start();
  }

  private getSceneChangeBaseInAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: -this.SceneChangeSize.width,
      target: 0,
      // eslint-disable-next-line no-restricted-properties
      easing: easeOutBaseQuint,
    });
    return animation;
  }

  private getSceneChangeLogoInAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.image,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: -this.SceneChangeSize.width - this.image.texture.width,
      target: this.SceneChangeSize.width / 2,
      // eslint-disable-next-line no-restricted-properties
      easing: easeOutLogoQuint,
    });
    return animation;
  }

  private getSceneChangeOutBaseAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: 0,
      target: this.SceneChangeSize.width,
      // eslint-disable-next-line no-restricted-properties
      easing: easeOutBaseQuint,
    });
    return animation;
  }

  private getSceneChangeOutLogoAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.image,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: this.SceneChangeSize.width / 2,
      target: this.SceneChangeSize.width + this.image.texture.width * 1.5,
      // eslint-disable-next-line no-restricted-properties
      easing: easeOutBaseQuint,
    });
    return animation;
  }

  private getFadeAnimationWait(duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      propertyBeginValue: 1,
      target: 1,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  private resize(width: number, height: number): void {
    this.SceneChangeSize = { width, height };
    this.rect.clear();
    this.rect.beginFill(SCENE_CHANGE_BACKGROUND_COLOR);
    this.rect.drawRect(0, 0, width, height);
    this.image.position.set(width / 2, height / 2);
    this.image.anchor.set(0.5);
  }

  private resizeScale(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    this.image.scale.set(scale);
  }
}
export default SceneChange;
